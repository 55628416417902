


export interface HttpConfiguration {
    getAuthorizationAccessToken?: () => string | null,
    onNotAuthorized?: (response: Response, resend: () => Promise<any>) => any,
    header?: any
}

/**
 * Http client
 *
 * @prop getAuthorizationAccessToken optional - function () => token ... used in header['Authorization']
 * @prop onNotAuthorized - optional - function (response: Response, resend: () => Promise<any>) => any ... use in status 401, 402, default:: return error
 * @prop header - optional - http Header ... default:: {'Content-Type': 'application/json;charset=utf-8'}
 * 
 * @return [httpGet, httpPost, httpPut, httpDelete]
 */
const createHttpClient = (data: HttpConfiguration): [<T>(url: string, params?: {}, options?: any) => Promise<T>, <T>(url: string, body: any, options?: any) => Promise<T>, <T>(url: string, body: any, options?: any) => Promise<T>, <T>(url: string, params?: {}) => Promise<T>] => {
    const getToken = data.getAuthorizationAccessToken;
    const onNotAuthorized = data.onNotAuthorized;

    let headerCfg: any = data.header ? data.header : { 'Content-Type': 'application/json', 'Accept': 'application/json' };

    async function httpFetch(method: string, url: string, params?: any, body?: any, options?: any): Promise<any> {
        let header: any = {};
        Object.assign(header, headerCfg);
        header = (body instanceof FormData) ? {} : header;

        const sendToken = options?.sendAuthToken != null ? options?.sendAuthToken : true;
        const paramToken = options?.useAuthTokenQuery != null ? options?.useAuthTokenQuery : false;
        const jsonStringfyOnBody = options?.useJsonStringfyOnBody != null ? options?.useJsonStringfyOnBody : true;

        if (getToken != null && getToken() != null && getToken() !== "null" && sendToken) {
            if (paramToken) {
                if (params != null) {
                    params.jwt = getToken();
                } else {
                    params = { "jwt": getToken() };
                }
            } else {
                header['Authorization'] = getToken();
            }
        }

        const urlX = new URL(url);

        if (params) {
            urlX.search = new URLSearchParams(params).toString();
        }

        const response = await fetch(urlX.toString(), {
            method: method,
            headers: header,
            mode: 'cors',
            body: body ? (jsonStringfyOnBody ? JSON.stringify(body) : body) : null
        });

        const resend = (): Promise<any> => {
            return httpFetch(method, url, params, body, options);
        }

        if (!response.ok) {
            // || response.status === 422
            if (response.status === 401) {
                if (onNotAuthorized != null) {
                    return onNotAuthorized(response, resend);
                } else {
                    return Promise.reject(await response.json() || 'HTTP error');
                }
            }
            return Promise.reject(await response.json() || 'HTTP error');
        }

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json();
        }
        if (contentType && contentType.indexOf("text/plain") !== -1) {
            return response.json();
        }
        if (contentType && contentType.indexOf("image/png") !== -1) {
            return response;
        }
        throw Error("Unsupported content type.")
    }

    async function httpGet<T>(url: string, params?: {}, options?: any): Promise<T> {
        return httpFetch('GET', url, params, undefined, options);
    }

    async function httpPost<T>(url: string, body: any, options?: any): Promise<T> {
        return httpFetch('POST', url, undefined, body, options);
    }

    async function httpPut<T>(url: string, body: any, options?: any): Promise<T> {
        return httpFetch('PUT', url, undefined, body, options);
    }

    async function httpDelete<T>(url: string, params?: {}, options?: any): Promise<T> {
        return httpFetch('DELETE', url, params, undefined, options);
    }

    return [httpGet, httpPost, httpPut, httpDelete];
}


export default createHttpClient;
