import { useState } from 'react'

export default function useSessionStorage<T>(keyName: string, defaultValue: T): [T, React.Dispatch<T>] {
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const value = window.sessionStorage.getItem(keyName)

            if (value) {
                return JSON.parse(value)
            }

            window.sessionStorage.setItem(keyName, JSON.stringify(defaultValue))

            return defaultValue
        } catch (err) {
            return defaultValue
        }
    })

    const setValue = (newValue: T): void => {
        try {
            window.sessionStorage.setItem(keyName, JSON.stringify(newValue))
        } catch (err) {
            return setStoredValue(newValue)
        }

        setStoredValue(newValue)
    }

    return [storedValue, setValue]
}
