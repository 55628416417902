import { Container } from '@mui/material';
import { loginLayoutStyles as styles } from './LoginLayoutStyles';

function LoginLayout(props: any) {
  return (
    <Container maxWidth={false} disableGutters={true} sx={[styles.container]}>
      {props.children}
    </Container>
  );
}

export default LoginLayout;
