
import React from "react";

/**
 * Share Data Context
 */

interface SharedContext {
    data: any,
    setData: (value: any) => void,
}

export const defaultContext = {
    data: {}
}

export const sharedContext = React.createContext<SharedContext>(undefined!);

export function useSharedContext(): SharedContext {
    return React.useContext<SharedContext>(sharedContext);
}

const SharedContextProvider = (props: { children: React.ReactNode; }) => {
    const [data, setData] = React.useState<any>(defaultContext.data);

    return (
        <sharedContext.Provider value={{ data, setData }}>
            {props.children}
        </sharedContext.Provider>
    );
};

export default SharedContextProvider;
