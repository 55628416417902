import React from 'react';
import createAuthProvider from '../lib/AuthManager';
import createHttpClient from '../lib/HttpClient';
import { User, UserPerms } from '../models/organizationModels';
import { refreshToken } from '../services/organizationService';
import useSessionStorage from '../hooks/useSessionStorage';


/**
 * Token authetication managment
 */
export const [useAuth, authLogin, authLogout, getAuthData] = createAuthProvider(
    {
        storageKey: 'authAgTechApp',
    }
);

/**
 * App User managment
 */
let appUser: User | null = null;
export const setAppUser = (user: User | null) => {
    appUser = user;
}
export const getAppUser = () => {
    return appUser;
}

/*
 * Http Client
 */
export const [httpGet, httpPost, httpPut, httpDelete] = createHttpClient(
    {
        getAuthorizationAccessToken: () => {
            return getAuthData() ? "Bearer " + getAuthData().access : null;
        },
        onNotAuthorized: async (response: Response, resend: () => Promise<any>) => {
            const data = await response.json();
            if (response.status === 401 && data.errors != null && data.errors[0].token_type === "access") {
                const authData = await refreshToken();
                authLogin(authData);
                return resend();
            } else {
                authLogout();
                setAppUser(null);
                return Promise.reject(await response.json() || 'HTTP error');
            }
        }
    }
)

/**
 * App Data Context
 */
export interface AppData {
    showCopyright: boolean,
}

interface AppContext {
    data: AppData,
    setData: (value: AppData) => void,
    storeUserPerms: (perms: any) => void,
    userPerms: UserPerms | null,
    logout: () => void,
}

const defaultContext = {
    data: {
        showCopyright: true,
    },
}

export const appContext = React.createContext<AppContext>(undefined!);

export function useAppContext(): AppContext {
    return React.useContext<AppContext>(appContext);
}

const AppContextProvider = (props: { children: React.ReactNode; }) => {
    const [data, setData] = React.useState<AppData>(defaultContext.data);
    const [userPerms, setUserPerms] = useSessionStorage<UserPerms | null>('userPerms', null)

    const storeUserPerms = (perms: UserPerms) => {
        setUserPerms(perms);
    }
    
    const logout = (): void => {
        authLogout();
        setUserPerms(null);
    }

    return (
        <appContext.Provider value={{ data, setData, storeUserPerms, userPerms, logout }}>
            {props.children}
        </appContext.Provider>
    );
};

export default AppContextProvider;
