import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from '../../../lib/i18n';
import {
  authLogin,
  setAppUser,
  useAppContext,
} from '../../../contexts/AppContext';
import { loginPageStyles as styles } from './LoginPageStyles';
import { useNavigate } from 'react-router-dom';
import {
  getDivisionUserList,
  getUser,
  getUserPerms,
  login,
} from '../../../services/organizationService';
import packageJson from '../../../../package.json';
import { paths } from '../../../utils/paths';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { LoadingProgress } from '../../../lib/components/LoadingProgress/LoadingProgress';

interface FormState {
  username: string;
  password: string;
  showPassword: boolean;
}

function LoginPage(props: any) {
  const navigate = useNavigate();
  const { storeUserPerms } = useAppContext();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [values, setValues] = React.useState<FormState>({
    username: '',
    password: '',
    showPassword: false,
  });
  const [errorPass, setErrorPass] = React.useState<boolean>(false);
  const [t] = useTranslation();

  useEffect(() => {
    console.log('Backoffice version ' + packageJson.version);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange =
    (prop: keyof FormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleLogin:
    | React.FormEventHandler<HTMLFormElement>
    | React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement> = async (
    e
  ) => {
    e.preventDefault();
    setIsLoading(true);
    login(values.username, values.password)
      .then(async (token) => {
        authLogin(token);
        const user = await getUser();
        const divisions = await getDivisionUserList();
        user.divisions = divisions;
        setAppUser(user);
        const perms = await getUserPerms();

        storeUserPerms(perms);
        navigate(paths.home);
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorPass(true);
        console.error('Error:', error);
        console.error('ErrorPass:', errorPass);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const changeLang = () => {
    changeLanguage();
    setValues({ ...values });
  };
  return (
    <>
      <Box sx={{ position: 'absolute', top: '1vh', right: '2vh' }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={changeLang}
          sx={{ borderRadius: '10px' }}
        >
          {t('LOGIN.CHANGE_LANGUAGE')}
        </Button>
      </Box>
      <Paper
        component="form"
        onSubmit={handleLogin}
        elevation={8}
        sx={[styles.mainPaper]}
      >
        <Stack width="100%" spacing={3} py={2} px={4} alignItems="center">
          <Box sx={[styles.logo]}>
            <img
              id="img-logo"
              src="/assets/images/lotia_copagro_logo.png"
              alt="Lotia-Copagro"
              width="100%"
              height="auto"
            />
          </Box>

          <Typography variant="h5" component="h2">
            {t('LOGIN.LOG_IN')}
          </Typography>

          <TextField
            data-testid="input-username"
            id="username"
            label={t('LOGIN.USER')}
            value={values.username}
            onChange={handleChange('username')}
            variant="outlined"
            required
            fullWidth
          />
          <FormControl error={errorPass} variant="outlined" required fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">
              {t('LOGIN.PASSWORD')}
            </InputLabel>
            <OutlinedInput
              data-testid="input-password"
              id="password"
              type={values.showPassword ? 'text' : 'password'}
              label={t('LOGIN.PASSWORD')}
              value={values.password}
              fullWidth
              onChange={handleChange('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errorPass && (
              <FormHelperText>{t('LOGIN.ERROR.PASS_WRONG')}</FormHelperText>
            )}
          </FormControl>
          <Button
            data-testid="button-login"
            color="primary"
            variant="contained"
            type="submit"
            disabled={isLoading}
            fullWidth
          >
            {' '}
            {t('LOGIN.ENTER')}{' '}
          </Button>
        </Stack>
        {isLoading && <LoadingProgress />}
      </Paper>
    </>
  );
}

export default LoginPage;
