export const loginPageStyles = {
  logo: {
    height: 'auto',
    width: {
      xs: '110%',
      sm: '90%',
      md: '80%',
      lg: '70%',
    },
  },
  mainPaper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    width: {
      xs: '80%',
      sm: '70%',
      md: '50%',
      lg: '30%',
    },
    borderRadius: '15px',
    padding: { xs: '10px', sm: '20px', md: '30px', lg: '40px' },
  },
  textField: {
    width: {
      xs: '85%',
      sm: '40ch',
    },
  },
};
