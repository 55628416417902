import { createTheme, styled } from '@mui/material/styles';
import { deepOrange, green } from "@mui/material/colors";
import MenuItem from '@mui/material/MenuItem';
import { Container } from '@mui/material';

export const themeMenu = createTheme({
    palette: {
        primary: {
            main: green[800],
        },
        secondary: {
            main: deepOrange[500],
        },
    },
});

export const fullLayoutStyles = {
    avatar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        height: "120px",
        backgroundColor: 'black',
        color: '#fff',
        maxWidth: 300,
        flexWrap: "wrap",
        //...theme.mixins.toolbar,
    },
    logo: {
        height: 40,
        backgroundColor: '#000000',
        //backgroundImage: "url(/assets/images/logo_completo.png)",
        //backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
    },
};

export const Content = styled(Container)(({ theme }) => ({
    maxWidth: "1280px",
    pb: "80px"
}));


export const ToolBarICon = styled(MenuItem)(({ theme }) => ({
    height: "40px",
    minHeight: "40px",
    backgroundColor: green[800],
    // backgroundImage: "url(/assets/images/logo_completo.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    flexGrow: 1,
    pointerEvents: "none",
}));
