import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import esjson from "../assets/i18n/es.json"
import enjson from "../assets/i18n/en.json"
import { useCallback, useEffect, useState } from "react";


const saveLanguage = (lang: string) => {
    localStorage.setItem('tecsoReact-lang', lang);
}

export const getLanguage = () => {
    const data = localStorage.getItem('tecsoReact-lang');
    const lang = data || 'es';

    return lang;
}

export const changeLanguage = () => {
    let lang = getLanguage();

    if (lang === "es") {
        lang = "en";
    } else {
        lang = "es";
    }
    saveLanguage(lang);
    initi18n();
    notify();
}

let listeners: any[] = [];

const subscribe = (listener: any) => {
    listeners.push(listener);
};
const unsubscribe = (listener: any) => {
    listeners = listeners.filter((l) => { return l !== listener; });
};

const notify = () => {
    const lang = getLanguage();
    listeners.forEach((l) => { return l(lang); });
};

export const useLang = () => {
    const [getLang, setGetLang] = useState(getLanguage())

    const listener = useCallback((newLang: string | ((prevState: string) => string)) => {
        setGetLang(newLang);
    }, [setGetLang]);

    useEffect(() => {
        subscribe(listener);
        return function () {
            unsubscribe(listener);
        };
    }, [listener]);
    return getLang;
};

const resources = {
    es: {
        translation: esjson
      },
    en: {
        translation: enjson
    }
};

const initi18n = () => {
    i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources,
            lng: getLanguage(),

            interpolation: {
                escapeValue: false // react already safes from xss
            }
        });
};

initi18n();

export default i18n;
