import { useCallback, useEffect, useState } from "react";

export interface AuthModel {
    storageKey: string,
    storage?: string,
    token?: string,
}

interface StorageData {
    storageKey: string,
    storage: string, // local, session
}


const createTokenProvider = (data: StorageData) => {
    const storageKey = data.storageKey;
    let storage: Storage = sessionStorage;
    if (data.storage === "local") {
        storage = localStorage;
    }

    let listeners: any[] = [];

    const subscribe = (listener: any) => {
        listeners.push(listener);
    };
    const unsubscribe = (listener: any) => {
        listeners = listeners.filter((l) => { return l !== listener; });
    };

    const getTokenInternal = () => {
        const data = storage.getItem(storageKey);
        const authData = (data && JSON.parse(data)) || null;
        return authData;
    };

    const getAuthData = () => {
        const authData = getTokenInternal();

        return authData;
    }

    const setAuthData = (authData: string | JSON | null) => {
        if (authData) {
            storage.setItem(storageKey, JSON.stringify(authData));
        }
        else {
            storage.removeItem(storageKey);
        }
        notify();
    };

    const notify = () => {
        const isLogged = isLoggedIn();
        listeners.forEach((l) => { return l(isLogged); });
    };

    const isLoggedIn = () => {
        return (getTokenInternal() != null);
    };

    return {
        getAuthData: getAuthData,
        isLoggedIn: isLoggedIn,
        setAuthData: setAuthData,
        subscribe: subscribe,
        unsubscribe: unsubscribe,
    };
}

const createAuthProvider = (data: AuthModel): [() => boolean, (s: any) => void, () => void, () => any] => {
    const storageKey = data.storageKey ? data.storageKey : "auth_token";

    const storageData: StorageData = {
        storageKey,
        storage: data.storage ? data.storage.trim().toLowerCase() : 'session'
    }
    const tp = createTokenProvider(storageData)

    const login = (authData: string | JSON) => {
        tp.setAuthData(authData);
    };

    const logout = () => {
        tp.setAuthData(null);
    };

    const useAuth = () => {
        const [isLogged, setIsLogged] = useState(tp.isLoggedIn())

        const listener = useCallback((newIsLogged: boolean | ((prevState: boolean) => boolean)) => {
            setIsLogged(newIsLogged);
        }, [setIsLogged]);

        useEffect(() => {
            tp.subscribe(listener);
            return function () {
                tp.unsubscribe(listener);
            };
        }, [listener]);
        return isLogged;
    };

    const getAuthData = () => {
        return tp.getAuthData();
    }

    return [useAuth, login, logout, getAuthData];
}


export default createAuthProvider;
