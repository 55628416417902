import {Box, CircularProgress } from "@mui/material";
import React from "react";


export const styles = {
    loading: {
        left: '50%',
        top:'50%',
        position: 'fixed',
        width: '100px',
        height: '100px',
        ml: {
            xs: '-15px',
            sm: '-50px'
        },
        mt: '-50px',
        zIndex: 9999,
    },
    loadingRight: {
        position: "relative",
        top: 10,
        left: 8,
    }
};



const LoadingProgress = (props: any) => {


    return (
        <React.Fragment>
            <Box sx={[styles.loading]}>
                <CircularProgress color="primary" />
            </Box>
        </React.Fragment>
    )
}
  
const LoadingRight = (props: any) => {

    return (
        <CircularProgress size={28} sx={[styles.loadingRight]}/>
    )
}


export {LoadingProgress, LoadingRight };
