import { deepOrange, red, green } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        button: {
            textTransform: 'none'
        },
    },
    palette: {
        primary: {
            main: green[800],
        },
        secondary: {
            main: deepOrange[500],
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#f5f5f6',

        },
    },
});

theme.typography.h5 = {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
        fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1.9rem',
    },
};

export default theme;
