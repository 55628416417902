import { grey, green } from '@mui/material/colors';

export const loginLayoutStyles = {
  gridContainer: {
    height: '100vh',
    maxWidth: 'unset',
    p: 0,
    backgroundColor: grey[300],
  },
  leftPanel: {
    height: '100vh',
    background: `linear-gradient(180deg, ${green[800]} 0%, ${green[800]} 100%)`,
    backgroundPosition: 'center',
    margin: 0,
    padding: 0,
    //backgroundImage: "url(/assets/images/logo.png)",
    //backgroundRepeat: "no-repeat",
    backgroundSize: 'contain',
    display: {
      xs: 'none',
      sm: 'block',
    },
  },
  container: {
    height: '100vh',
    background: `linear-gradient(180deg, ${green[800]} 0%, ${green[700]} 20%,  ${green[600]} 40%, ${green[500]} 60%, ${green[400]} 80%, ${green[300]} 100%)` ,
    backgroundSize: 'contain',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    mt: '200px',
    height: 160,
    backgroundColor: green[800],
    // backgroundImage: "url(/assets/images/logo_completo.png)",
    // backgroundRepeat: "no-repeat",
    backgroundSize: 'contain',
  },
};
