import AccountCircle from '@mui/icons-material/AccountCircle';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HomeIcon from '@mui/icons-material/Home';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MenuIcon from '@mui/icons-material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ThemeProvider from '@mui/system/ThemeProvider';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import React from 'react';
import {
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppContext, getAppUser, setAppUser } from '../contexts/AppContext';
import { useTranslation } from 'react-i18next';
import {
  Content,
  fullLayoutStyles as styles,
  themeMenu,
  ToolBarICon,
} from './FullLayoutStyles';
import { useSharedContext } from '../contexts/SharedContext';
import { changeLanguage, getLanguage } from '../lib/i18n';
import { paths } from '../utils/paths';

function FullLayout(props: any) {
  const theme = useTheme();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const [t] = useTranslation();

  const sharedContext = useSharedContext();
  const { logout } = useAppContext();

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleChangePassword = () => {
    goto(paths.changePassword);
    handleMobileMenuClose();
    handleMenuClose();
  };

  const goto = (rute: string) => {
    sharedContext.setData({});
    navigate(rute);
    handleDrawerClose();
  };

  const handleLogout = () => {
    logout();
    setAppUser(null);
  };

  const menuId = 'primary-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleChangePassword}>
        {t('LAYOUT.CHANGE_PASSWORD')}
      </MenuItem>
      <Divider />
      {getLanguage() === 'en' && (
        <MenuItem onClick={() => changeLanguage()}>Español</MenuItem>
      )}
      {getLanguage() === 'es' && (
        <MenuItem onClick={() => changeLanguage()}>English</MenuItem>
      )}
      <Divider />
      <MenuItem onClick={handleLogout}>{t('LAYOUT.LOGOUT')}</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/*
            <MenuItem>
                <IconButton color="inherit">
                    <Badge badgeContent={4} color="secondary">
                        <LanguageIcon />
                    </Badge>
                </IconButton>
                <p>{t("LAYOUT.MESSAGES")}</p>
            </MenuItem>
            <MenuItem>
                <IconButton color="inherit">
                    <Badge badgeContent={11} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>{t("LAYOUT.NOTIFICATIONS")}</p>
            </MenuItem>
            */}
      <MenuItem onClick={handleChangePassword}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>{t('LAYOUT.CHANGE_PASSWORD')}</p>
      </MenuItem>
      <Divider />
      {getLanguage() === 'en' && (
        <MenuItem onClick={() => changeLanguage()}>Español</MenuItem>
      )}
      {getLanguage() === 'es' && (
        <MenuItem onClick={() => changeLanguage()}>English</MenuItem>
      )}
      <Divider />
      <MenuItem onClick={handleLogout}>
        <IconButton color="inherit">
          <ExitToAppIcon />
        </IconButton>
        <p>{t('LAYOUT.LOGOUT')}</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <ThemeProvider theme={themeMenu}>
        <AppBar position="absolute">
          <Toolbar>
            <IconButton
              edge="start"
              sx={{ mr: theme.spacing(2) }}
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
            <ToolBarICon></ToolBarICon>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
              {/*
                            <IconButton color="inherit">
                                <LanguageIcon />
                            </IconButton>
                            <IconButton color="inherit">
                                <Badge badgeContent={17} color="secondary">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                            */}
              <IconButton
                edge="end"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Box>
            <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' } }}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
        <Box>
          <Drawer
            open={openDrawer}
            onClose={handleDrawerClose}
            sx={{ '& .MuiPaper-root': { minWidth: 250 } }}
          >
            <Box sx={[styles.avatar]}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Box component={'p'} sx={[styles.logo]}></Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">{getAppUser()?.email}</Typography>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <List>
              <ListItemButton onClick={() => goto(paths.home)}>
                <ListItemIcon>
                  <HomeIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary={t('MENU.HOME')} />
              </ListItemButton>
              <ListSubheader component="div">
                {t('MENU.ADMIN.TITLE')}
              </ListSubheader>
              {/*<ListItemButton  onClick={() => goto("/admin/user")} >
                                <ListItemIcon><PeopleIcon color="secondary"/></ListItemIcon>
                                <ListItemText primary={ t("MENU.ADMIN.USER") }/>
                            </ListItemButton>*/}
              {/*<ListItemButton  onClick={() => goto("/test/list")} >
                                <ListItemIcon><PeopleIcon color="secondary"/></ListItemIcon>
                                <ListItemText primary={ "test" }/>
                            </ListItemButton>*/}
              <ListItemButton onClick={() => goto(paths.productiveUnits)}>
                <ListItemIcon>
                  <AgricultureIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary={t('PRODUCTIVE_UNIT.PRODUCTIVE_UNITS')} />
              </ListItemButton>
              <ListItemButton onClick={() => goto(paths.harvests)}>
                <ListItemIcon>
                  <ListAltIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary={t('PRODUCTIVE_UNIT.HARVESTS')} />
              </ListItemButton>
              <ListItemButton onClick={() => goto(paths.events)}>
                <ListItemIcon>
                  <EventNoteIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary={t('EVENT.EVENTS')} />
              </ListItemButton>
            </List>
          </Drawer>
        </Box>
      </ThemeProvider>
      <Box
        component={'main'}
        sx={{ flexGrow: 1, height: '92vh', overflow: 'auto', mt: '62px' }}
      >
        <Box sx={{ mt: { xs: '5px', sm: '5px', md: '20px' } }} />
        <Content maxWidth={false}>{props.children}</Content>
      </Box>
    </Box>
  );
}

export default FullLayout;
